/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {Component} from "react"
import propTypes from "prop-types"

class Layout extends Component { 

  render(){

    return (
      <>
          <main>{this.props.children}</main>
      </>
    )
  }

}

Layout.propTypes = {
  children: propTypes.node.isRequired,
}

export default Layout;